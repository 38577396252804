.swal2-container {
    &.swal2-backdrop-show {
        z-index: 10001;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.MuiFormControl-root .react-tel-input * {
    box-sizing: unset;
}

.MuiFormControl-root .react-tel-input .flag-dropdown {
    border: 0;
}

.MuiFormControl-root .react-tel-input .flag-dropdown {
    background: none;
    border: 0;
}

.MuiFormControl-root .react-tel-input .flag-dropdown.open,
.MuiFormControl-root .react-tel-input .flag-dropdown.open .selected-flag,
.MuiFormControl-root .react-tel-input .selected-flag:hover,
.MuiFormControl-root .react-tel-input .selected-flag:focus {
    background: transparent;
}

.MuiFormControl-root .react-tel-input .MuiInput-input {
    padding-left: 42px;
}

.MuiFormControl-root .react-tel-input .country-list {
    border-radius: 5px;
}

.MuiFormControl-root .react-tel-input .country-list .search-box {
    border: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.094rem;
    color: #c1c4d6;
    width: 85%;
}

.MuiFormControl-root .react-tel-input .country-list .search {
    border-bottom: 1px solid #f7f8fa;
}

.MuiFormControl-root .react-tel-input .country-list .country {
    color: #9898b1;
}

.MuiFormControl-root .react-tel-input .country-list .country:hover {
    background: #f2f4f8;
}

.iconBtn {
    .MuiButton-startIcon {
        margin: 0;
    }
}
